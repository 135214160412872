.language {
    display: inline;
  }
  .heading {
    font-size: 20px;
  }
  #source {
    width: 100% !important;
    background: #cccccc52;
    height: 50vh;
    font-size: 15px;
    resize: vertical;
  }
  #input {
    background: #cccccc52;
    width: 45.6%;
    height: 17vh;
    resize: vertical;
    font-size: 17px;
  }
  #output {
    background: #cccccc52;
    width: 50%;
    height: 100%;
    position: fixed;
    top: 7.6%;
    font-size: 18px;
    right: 0;
    resize: none;
  }
  .language {
    width: 150px;
  }